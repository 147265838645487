.map-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex: '1 0 100%';
}

.leaflet-container {
  flex: 1;
}

.leaflet-div-icon {
  background: transparent;
  border: 0 transparent;
}
