.main-navigation {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  display: inline-block;
  font-size: 1.2rem;
  padding: 0.5rem;
}

.nav-item a {
  color: var(--brand-color);
}
